import {makeDependency} from '@/container';
import {computed, Ref, ref, UnwrapRefSimple, watch} from '@vue/composition-api';
import {Point} from '@/common/types/Point';
import {maxBy, minBy, sortBy} from 'lodash';
import {calculateAxisMinMaxValue} from '@/common/utils/graphs';
import {clone} from '@/common/utils/clone';
import {DataTableHeader} from 'vuetify';

export type Row<Keys extends string | number | symbol> = {
  [key in Keys]: null | number;
};

export const useUOttawaTask5SlopeIntercept = makeDependency(makeUOttawaTask5SlopeIntercept);

// FIXME: We cannot get better typing on inputs while using 'inputsDataProp'
export function makeUOttawaTask5SlopeIntercept<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
>(
  labels: Labels,
  x: X,
  y: Y,
  inputsDataProp: string,
  isMarking: boolean,
  linearEquationY: string,
  linearEquationX: string,
  minY: number | null,
  maxY: number | null,
  minX: number | null,
  maxX: number | null
) {
  const newRow = {} as Row<keyof Labels>;
  Object.keys(labels).map((label) => (newRow[label as keyof Row<keyof Labels>] = null));

  const inputs = ref({
    slope: null,
    intercept: null,
    [inputsDataProp]: [] as Row<keyof Labels>[],
  });

  watch(
    () => inputs.value[inputsDataProp],
    () => {
      const dataPoints = inputs.value[inputsDataProp];
      if (!dataPoints || dataPoints.length === 0) {
        inputs.value[inputsDataProp] = [clone(newRow)] as UnwrapRefSimple<Row<keyof Labels>[]>;
      }
    },
    {
      immediate: true,
    }
  );

  const colDefs: Ref<DataTableHeader[]> = ref([
    {
      text: 'Run',
      value: 'index',
      sortable: false,
    },
  ]);
  Object.entries(labels).map(([value, text]) => colDefs.value.push({text, value, sortable: false}));

  if (!isMarking) {
    colDefs.value.push({
      text: '',
      value: 'id',
      sortable: false,
    });
  }

  const equation: Ref<string> = computed(() => {
    const interceptValue = inputs.value.intercept ?? 0;
    const slopeValue = inputs.value.slope;
    const equationOperation =
      slopeValue !== null && interceptValue !== null ? (interceptValue >= 0 ? '+' : '-') : '';
    return `$${linearEquationY} = \\displaystyle{${slopeValue ?? ''}${
      slopeValue !== null ? linearEquationX + ' ' : ''
    }${equationOperation ? equationOperation + ' ' : ''}${
      interceptValue !== null
        ? slopeValue !== null
          ? Math.abs(interceptValue)
          : interceptValue
        : ''
    }}$`;
  });

  const plotPoints: Ref<Point[]> = computed(() => {
    const points = inputs.value?.[inputsDataProp]
      // @ts-ignore -- TS2590: Expression produces a union type that is too complex to represent.
      ?.filter((point) => !!point[x] && !!point[y])
      .map((run: any) => {
        return {
          x: run[x],
          y: run[y],
        };
      });

    return sortBy(points, 'x');
  });

  const apexSeries = computed(() => {
    return [
      {
        name: labels[y],
        type: 'scatter',
        data: plotPoints.value,
      },
    ];
  });

  const apexOptions = computed(() => {
    return {
      chart: {
        height: 350,
      },
      markers: {
        size: 6,
      },
      fill: {
        opacity: 0.0,
      },
      legend: {
        show: false,
      },
      title: {
        text: "Boyle's Law Plot",
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238',
        },
      },
      xaxis: {
        title: {
          style: {
            fontSize: '14px',
          },
          text: labels[x],
        },
        type: 'numeric',
        tickAmount: 10,
        decimalsInFloat: 3,
      },
      yaxis: {
        title: {
          text: labels[y],
          style: {
            fontSize: '14px',
          },
        },
        type: 'numeric',
        tickAmount: 10,
        decimalsInFloat: 3,
      },
    };
  });

  function addRun() {
    inputs.value[inputsDataProp]?.push(clone(newRow) as UnwrapRefSimple<Row<keyof Labels>>);
  }

  function removeRun(index: number) {
    inputs.value[inputsDataProp]?.splice(index, 1);
  }

  return {
    inputs,
    colDefs,
    equation,
    plotPoints,
    apexSeries,
    apexOptions,
    addRun,
    removeRun,
  };
}
